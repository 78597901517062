import { createApp } from "vue";
import { createPinia } from "pinia";
import { Chart, Filler } from "chart.js";
Chart.register(Filler);
import App from "./App.vue";
import router from "./router/index";
import "./assets/tailwind.css";
import "../public/css/style.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "vue-multiselect/dist/vue-multiselect.css";

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.mount("#app");