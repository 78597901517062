import { defineStore } from "pinia";

export const useErrorStore = defineStore("errorStore", {
  state: () => ({
    statusCode: 404,
    message: "ページが見つかりません",
  }),
  actions: {
    setError(status, message) {
      this.statusCode = status;
      this.message = message;
    },
    clearError() {
      this.statusCode = null;
      this.message = "";
    },
  },
  persist: true,
});
