import { useErrorStore } from "@/stores/errorStore";
import { createPinia } from "pinia";

const pinia = createPinia();
const errorStore = useErrorStore(pinia);

/**
 * エラー画面に遷移する関数
 * @param {Number} status - HTTP ステータスコード
 * @param {String} message - エラーメッセージ
 */
export const redirectToErrorPage = (status, message) => {
  errorStore.setError(status, message);
};
