import apiClient from "@/api/apiClient";

export const loginUser = async (email, password) => {
  return apiClient.post("/login", {
    email,
    password,
  });
};

export const logoutUser = async () => {
  return apiClient.post("/logout");
};

export default apiClient;
