import { createRouter, createWebHistory } from "vue-router";
import { redirectToErrorPage } from "@/utils/errorHandler";
import { passwordResetTokenCheck } from "@/api/passwordReset";
import { egAdminRegistTokenCheck } from "@/api/admin";
import { useAuthStore } from "@/stores/authStore";

const AdminPage = () => import("@/pages/menu/admin-page.vue");
const BusinessMetrics = () => import("@/pages/business/business-metrics-page.vue");
const SearchPage = () => import("@/pages/samples/search-page.vue");
const ResultPage = () => import("@/pages/samples/result-page.vue");
const SkillPage = () => import("@/pages/skills/skills-page.vue");
const SkillDetailPage = () => import("@/pages/skills/skill-detail-page.vue");
const SkillCategoryPage = () =>
  import("@/pages/skills/skill-category-page.vue");
const EmailTemplateList = () =>
  import("@/pages/email-templates/email-template-list.vue");
const EmailTemplateAdd = () =>
  import("@/pages/email-templates/email-template-add.vue");
const EmailTemplateDetail = () =>
  import("@/pages/email-templates/email-template-detail.vue");
const ErrorPage = () => import("@/pages/error-page.vue");
const LoginPage = () => import("@/pages/auth/login-page.vue");
const OtpVerifyPage = () => import("@/pages/auth/otp-verify-page.vue");
const PasswordResetMail = () =>
  import("@/pages/password-reset/password-reset-mail-page.vue");
const PasswordResetMailComplete = () =>
  import("@/pages/password-reset/password-reset-mail-complete-page.vue");
const PasswordReset = () =>
  import("@/pages/password-reset/password-reset-page.vue");
const PasswordResetComplete = () =>
  import("@/pages/password-reset/password-reset-complete-page.vue");
const UserManagementLists = () =>
  import("@/pages/users/user-management-page.vue");
const UserDetail = () => import("@/pages/users/user-detail.vue");
const RestoreConfirmationPage = () =>
  import("@/components/user-management/restore-confirmation-page.vue");
const DeleteConfirmationPage = () =>
  import("@/components/user-management/delete-confirmation-page.vue");
const UserDeletionPage = () =>
  import("@/components/user-management/user-deletion-page.vue");
const UserRestorePage = () =>
  import("@/components/user-management/user-restore-page.vue");
const ChangeEmailAddress = () =>
  import("@/components/user-management/email-change-form.vue");
const ResetPassword = () =>
  import("@/components/user-management/password-reset-page.vue");
const DeleteMultipleUsers = () =>
  import("@/components/user-management/delete-several-users-page.vue");
const Maintenance = () => import("@/pages/maintenance-page.vue");
const ScheduleMaintenance = () =>
  import("@/pages/schedule-maintenance-page.vue");
const EditMaintenanceRecord = () => import("@/pages/edit-maintenance-page.vue");
const CategoryCreatePage = () =>
  import("@/pages/categories/category-create-page.vue");
const CategoryDetailPage = () =>
  import("@/pages/categories/category-detail-page.vue");
const AdminLogsPage = () =>
  import("../pages/admin/admin-logs-page.vue");
const AdminManagementPage = () =>
  import("../pages/admin/admin-management-page.vue");
const AdminAccountRegister = () =>
  import("../components/admin/form/admin-account-register.vue");
const AdminAccountCreationPage = () =>
  import("../pages/admin/admin-account-creation-page.vue");
const AdminAccountConfirmation = () =>
  import("../pages/admin/admin-account-confirmation.vue");
const AdminAccountDeletionConfirmation = () =>
  import("../pages/admin/admin-account-deletion-confirmation.vue");
const CategoryPage = () => import("@/pages/categories/category-page.vue");
const GreetingPage = () => import("@/pages/greeting-page.vue");
const FaqManagement = () => import("@/pages/faq/faq-management-page.vue");
const TestRegistPage = () => import("@/pages/samples/test-regist.vue");

const routes = [
  // Public routes (no authentication required)
  {
    path: "/",
    component: LoginPage,
    name: "LoginPage",
    meta: { requiresAuth: false }
  },
  {
    path: "/test-regist",
    component: TestRegistPage,
    name: "TestRegistPage",
    meta: { requiresAuth: false }
  },
  {
    path: "/greeting",
    component: GreetingPage,
    name: "GreetingPage",
    meta: { requiresAuth: false }
  },
  {
    path: "/password-reset/mail",
    component: PasswordResetMail,
    name: "PasswordResetMail",
    meta: { requiresAuth: false }
  },
  {
    path: "/password-reset/mail-complete",
    component: PasswordResetMailComplete,
    name: "PasswordResetMailComplete",
    meta: { requiresAuth: false }
  },
  {
    path: "/password-reset/:id/:token",
    component: PasswordReset,
    name: "PasswordReset",
    meta: { requiresAuth: false },
    beforeEnter: async (to, from, next) => {
      try {
        // パスパラメータをチェックし、正規のURLか確認
        const { id, token } = to.params;
        const response = await passwordResetTokenCheck(id, token);
        if (response.isSuccess === false) {
          redirectToErrorPage(response.status, response.message);
          next({ name: "ErrorPage" });
        }
        next();
      } catch (e) {
        redirectToErrorPage(
          500,
          "予期せぬエラーが発生しました。システム管理者にご連絡してください。"
        );
        next({ name: "ErrorPage" });
      }
    },
  },
  {
    path: "/password-reset/complete",
    component: PasswordResetComplete,
    name: "PasswordResetComplete",
    meta: { requiresAuth: false }
  },
  {
    path: "/verify-otp",
    component: OtpVerifyPage,
    name: "OtpVerifyPage",
    meta: { requiresAuth: false }
  },
  {
    path: "/error",
    component: ErrorPage,
    name: "ErrorPage",
    meta: { requiresAuth: false }
  },

  // Protected routes (authentication required)
  {
    path: "/sample",
    component: SearchPage,
    name: "SearchPage",
    meta: { requiresAuth: true }
  },
  {
    path: "/sample/result",
    component: ResultPage,
    name: "ResultPage",
    meta: { requiresAuth: true }
  },
  {
    path: "/maintenance",
    component: Maintenance,
    meta: { breadcrumb: "Maintenance", requiresAuth: true },
  },
  {
    path: "/maintenance/edit/:maintenanceId",
    component: EditMaintenanceRecord,
    meta: { breadcrumb: "Edit Maintenance", requiresAuth: true },
  },
  {
    path: "/maintenance/schedule",
    component: ScheduleMaintenance,
    meta: { breadcrumb: "Maintenance Implementation Schedule", requiresAuth: true },
  },
  {
    path: "/admin",
    component: AdminPage,
    name: "Admin Menu",
    meta: { requiresAuth: true }
  },
  {
    path: "/email-templates",
    component: EmailTemplateList,
    name: "EmailTemplateList",
    meta: { requiresAuth: true }
  },
  {
    path: "/email-templates/add",
    component: EmailTemplateAdd,
    name: "EmailTemplateAdd",
    meta: { requiresAuth: true }
  },
  {
    path: "/email-templates/:id",
    component: EmailTemplateDetail,
    name: "EmailTemplateDetail",
    meta: { requiresAuth: true }
  },
  {
    path: "/skills",
    component: SkillPage,
    name: "SkillPage",
    meta: { requiresAuth: true }
  },
  {
    path: "/skills/add",
    name: "SkillAdd",
    component: SkillDetailPage,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/skills/categories/add",
    name: "SkillCategoryAdd",
    component: SkillCategoryPage,
    props: true
  },
  {
    path: "/skills/:mode/:id",
    name: "SkillDetails",
    component: SkillDetailPage,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/admin-logs",
    name: "AdminLogsPage",
    component: AdminLogsPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/admin-accounts",
    name: "AdminManagementPage",
    component: AdminManagementPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/create-admin-account",
    name: "AdminAccountCreationPage",
    component: AdminAccountCreationPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/delete-admin-account/:id",
    name: "AdminAccountConfirmation",
    component: AdminAccountConfirmation,
    meta: { requiresAuth: true }
  },
  {
    path: "/delete-confirm/:id",
    name: "AdminAccountDeletionConfirmation",
    component: AdminAccountDeletionConfirmation,
    meta: { requiresAuth: true }
  },
  {
    path: "/create-admin-account",
    name: "AdminAccountCreation",
    component: AdminAccountCreationPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/admin-register/:id/:token",
    name: "AdminAccountRegister",
    component: AdminAccountRegister,
    beforeEnter: async (to, from, next) => {
      try {
        // パスパラメータをチェックし、正規のURLか確認
        const { id, token } = to.params;
        const response = await egAdminRegistTokenCheck(id, token);
        if (response.isSuccess === false) {
          redirectToErrorPage(response.status, response.message);
          next({ name: "ErrorPage" });
        }
        next();
      } catch (e) {
        redirectToErrorPage(
          500,
          "予期せぬエラーが発生しました。システム管理者にご連絡してください。"
        );
        next({ name: "ErrorPage" });
      }
    },
  },
  {
    path: "/skills/categories/:mode/:id",
    name: "SkillCategoryEdit",
    component: SkillCategoryPage,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/faq",
    component: FaqManagement,
    name: "FaqManagement",
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/user-management",
    component: UserManagementLists,
    name: "UserManagementLists",
    meta: { requiresAuth: true }
  },
  {
    path: "/user-management/:id",
    component: UserDetail,
    name: "UserDetail",
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/user-management/change-email-address/:id",
    component: ChangeEmailAddress,
    name: "ChangeEmailAddress",
    meta: { requiresAuth: true }
  },
  {
    path: "/user-management/password-reset/:id",
    component: ResetPassword,
    name: "ResetPassword",
    meta: { requiresAuth: true }
  },
  {
    path: "/users/:id/delete",
    name: "UserDeletionPage",
    component: UserDeletionPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/users/:id/restore",
    name: "UserRestorePage",
    component: UserRestorePage,
    meta: { requiresAuth: true }
  },
  {
    path: "/user-management/delete-multiple",
    component: DeleteMultipleUsers,
    name: "DeleteMultipleUsers",
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/users/:id/restore/confirm",
    name: "RestoreConfirmationPage",
    component: RestoreConfirmationPage,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/users/:id/delete-confirmation",
    name: "DeleteConfirmationPage",
    component: DeleteConfirmationPage,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/categories",
    component: CategoryPage,
    name: "CategoryPage",
    meta: { breadcrumb: "Categories", requiresAuth: true },
  },
  {
    path: "/categories/create",
    component: CategoryCreatePage,
    name: "CategoryCreatePage",
    meta: { breadcrumb: "Create Category", requiresAuth: true },
  },
  {
    path: "/categories/:id",
    component: CategoryDetailPage,
    name: "CategoryDetailPage",
    meta: { breadcrumb: "Category Details", requiresAuth: true },
    props: true,
  },
  {
    path: "/business-metrics",
    component: BusinessMetrics,
    name: "BusinessMetrics",
    meta: { requiresAuth: true }
  },
  { path: "/:pathMatch(.*)*", redirect: "/error" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add navigation guard
// Update the navigation guard in router/index.js
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  // If route requires authentication and user is not authenticated
  if (requiresAuth && !authStore.isAuthenticated) {
    // Redirect to login page using path
    next('/');  // Use path to login page
  }
  // If user is authenticated and trying to access login page, redirect to admin
  else if (authStore.isAuthenticated && to.path === '/') {
    next('/admin');  // Use path to admin page
  }
  else {
    // Continue navigation
    next();
  }
});

export default router;
