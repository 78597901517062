import axios from "axios";
import router from "@/router";

const baseURL = process.env.VUE_APP_API_BASE_URL || "/api";

const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the token in all requests
apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 errors
apiClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // If we get a 401 response, clear auth and redirect to login
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('tmpToken');

      // Remove Authorization header
      delete apiClient.defaults.headers.common['Authorization'];

      // Only redirect to login if not already on login page
      if (router.currentRoute.value.name !== 'LoginPage' &&
        router.currentRoute.value.name !== 'OtpVerifyPage') {
        router.push({ name: 'LoginPage' });
      }
    }
    return Promise.reject(error);
  }
);

// Check if token is already in localStorage on initialization
const token = localStorage.getItem('authToken');
if (token) {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export default apiClient;
