import { defineStore } from "pinia";
import { loginUser, logoutUser } from "@/api/auth";
import apiClient from "@/api/apiClient";
import { redirectToErrorPage } from "@/utils/errorHandler";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    email: "",
    token: localStorage.getItem('authToken') || null,
    errors: {
      email: "",
      password: "",
      submit: "",
    },
    isLoading: false,
    isSuccess: false,
    error: null,
    success: false,
  }),

  getters: {
    isAuthenticated: (state) => !!state.token,
  },


  actions: {
    validateEmail(email) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!email) {
        this.errors.email = "メールアドレスは必須です。";
        return false;
      }
      if (email.length > 320) {
        this.errors.email = "メールアドレスは320文字以下で入力してください。";
        return false;
      }
      if (!emailPattern.test(email)) {
        this.errors.email = "有効なメールアドレスを入力してください。";
        return false;
      }
      this.errors.email = "";
      return true;
    },

    validatePassword(password) {
      const passwordPattern = /^(?=(.*[a-z]))(?=(.*[A-Z]))(?=(.*\d))|(?=(.*[a-z]))(?=(.*[A-Z]))(?=(.*\W))|(?=(.*[a-z]))(?=(.*\d))(?=(.*\W))|(?=(.*[A-Z]))(?=(.*\d))(?=(.*\W))/;
      if (!password) {
        this.errors.password = "パスワードは必須です。";
        return false;
      }
      if (password.length < 8) {
        this.errors.password = "パスワードは8文字以上で入力してください。";
        return false;
      }
      if (password.length > 64) {
        this.errors.password = "パスワードは64文字以下で入力してください。";
        return false;
      }
      if (!passwordPattern.test(password)) {
        this.errors.password = "パスワードは大文字アルファベット・小文字アルファベット・数字・記号のうち3種類以上使用して入力してください。";
        return false;
      }
      this.errors.password = "";
      return true;
    },

    async handleLogin(router, email, password) {
      this.isLoading = true;
      this.errors.submit = "";

      const isEmailValid = this.validateEmail(email);
      const isPasswordValid = this.validatePassword(password);

      if (!isEmailValid || !isPasswordValid) {
        this.isLoading = false;
        return false;
      }

      try {
        const response = await loginUser(email, password);
        if (response.data.isSuccess === true) {
          localStorage.setItem("tmpToken", response.data.datas.tmpToken);
          localStorage.setItem("user_id", response.data.datas.user_id || response.data.datas.admin_id || null);
          this.email = email;
          return response.data.isSuccess;
        } else {
          this.errors.submit = response.data.message;
          return response.data.isSuccess;
        }
      } catch (error) {
        if(error.response.status === 422) {
          if(error.response.data.message.email.length > 0){
            this.errors.email = error.response.data.message.email[0];
          }
          if(error.response.data.message.password.length > 0){
            this.errors.password = error.response.data.message.password[0];
          }
          return false;
        }
        if(error.response.status === 401 || error.response.status === 423) {
          this.errors.submit = error.response.data.message;
          return false;
        }
        redirectToErrorPage(
          error.response?.status ?? 500,
          error.response?.data?.message ?? "予期せぬエラーが発生しました。管理者にお問い合わせください。"
        );
        router.push({ name: "ErrorPage" });
      } finally {
        this.isLoading = false;
      }
    },
    // Set auth token after OTP verification
    setAuthToken(token) {
      this.token = token;
      localStorage.setItem('authToken', token);
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      this.isSuccess = true;
    },

    // Handle logout
    async handleLogout(router) {
      this.isLoading = true;
      
      try {
        // Call logout API if authenticated
        if (this.token) {
          await logoutUser();
        }
      } catch (error) {
        console.error('Logout error:', error);
      } finally {
        // Clear authentication state
        this.clearAuth();
        this.isLoading = false;
        if (router) router.push({ name: 'LoginPage' });
      }
    },
    
    // Clear auth state
    clearAuth() {
      // Clear tokens from localStorage
      localStorage.removeItem('authToken');
      localStorage.removeItem('tmpToken');
      
      // Clear state
      this.token = null;
      this.email = "";
      this.isSuccess = false;
      
      // Remove Authorization header
      delete apiClient.defaults.headers.common['Authorization'];
    },    
    initAuth() {
      const token = localStorage.getItem('authToken');
      
      if (token) {
        this.token = token;
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    }
  },
});
