<template>
  <router-view />
</template>

<script>
import { useAuthStore } from "@/stores/authStore";

export default {
  name: "App",
  setup() {
    const authStore = useAuthStore();
    
    // Initialize authentication state when app starts
    authStore.initAuth();
    
    return {};
  }
};
</script>
