import apiClient from '@/api/apiClient';

export const getAdminAccount = async () => {
    return apiClient.get(`/admin`);
};

export const getAdminAccountById = async (adminId) => {
    return apiClient.get(`/admin/${adminId}`);
};
// for delete admin account
export const deleteAdminAccount = async (adminId) => {
    return apiClient.delete(`/admin/${adminId}/delete`);
};

// for update admin data
export const updateAdminAccount = async (adminId, adminData) => {
    return apiClient.patch(`/admin/${adminId}/update`, adminData);
};
// for get admin logs
export const getAdminLogs = async () => {
    return apiClient.get(`/admin/logs`);
};

// Function to send invitation email
export const InvitationMail = async (email, role) => {
    return apiClient.post(`/admin/send`, { email: email, role: role }, {
    });
};

export const egAdminRegistTokenCheck = async (id, token) => {
    return apiClient.get(`/admin/create/${id}/${token}`);
};
  
export const createAdminAccount = async (
    id,
    token,
    user_name,
    password,
    password_confirmation,
) => {
return apiClient.post("/admin/create", {
    id: id,
    token: token,
    user_name: user_name,
    password: password,
    password_confirmation: password_confirmation,
});
};
export default apiClient;
