import apiClient from "@/api/apiClient";

export const sendPasswordResetMail = async (email) => {
  return apiClient.post("/password-reset/send", { email });
};

export const passwordResetTokenCheck = async (id, token) => {
  return apiClient.get(`/password-reset/${id}/${token}`);
};

export const passwordReset = async (
  id,
  token,
  password,
  passwordConfirmation
) => {
  return apiClient.post("/password-reset", {
    id: id,
    token: token,
    password: password,
    password_confirmation: passwordConfirmation,
  });
};
